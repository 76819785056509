import React, {
  createContext,
  useState,
  FC,
  PropsWithChildren,
  useContext,
} from "react";

export type Section = {
  id: number;
  elementRef: HTMLDivElement | null;
};

export type AppContextType = {
  activeSectionId: number;
  sections: Section[];
  setActiveSectionId: (id: number) => void;
  updateSection: (section: Section) => void;
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
};
const AppContext = createContext<AppContextType>({
  activeSectionId: 0,
  sections: [],
  setActiveSectionId: () => {},
  updateSection: () => {},
  isMenuOpen: false,
  setIsMenuOpen: () => {},
});

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeSectionId, setActiveSectionId] = useState<number>(0);
  const [sections, setSections] = useState<Section[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const updateSection = (section: Section) => {
    setSections((previousSections) => {
      const newSections = [...previousSections];
      const sectionIndex = previousSections.findIndex(
        (existingSection) => existingSection.id === section.id
      );
      if (sectionIndex !== -1) {
        newSections[sectionIndex] = section;
      } else {
        newSections.push(section);
      }
      return [...newSections];
    });
  };

  return (
    <AppContext.Provider
      value={{
        activeSectionId,
        sections,
        updateSection,
        setActiveSectionId,
        isMenuOpen,
        setIsMenuOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};

export default AppProvider;
