import { Box, Container, useTheme } from '@mui/material';
import { colors } from '../styles/colors';
import React, { useState, useEffect, FC } from 'react';
import Navigation from './Navigation';
import logo from '../../static/images/spaccanapoli-logo.svg';
import { ScrollDirection, useScrollDirection } from '../hooks/useScrollDirection';
import { dimensions } from '../styles/dimensions';
import { useNavigation } from '../hooks/useNavigation';
import { useAppContext } from '../context/AppContext';

const Header: FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { scrollDirection } = useScrollDirection();
  const theme = useTheme();
  const { scrollToSection } = useNavigation();
  const { setIsMenuOpen } = useAppContext();

  useEffect(() => {
    setIsVisible(scrollDirection === ScrollDirection.DOWN ? false : true);
  }, [scrollDirection]);

  return (
    <Box
      sx={{
        backgroundColor: colors.isabelline,
        position: 'fixed',
        top: isVisible ? 0 : -500,
        left: 0,
        zIndex: 101,
        width: '100%',
        boxShadow: 8,
        transition: 'top 350ms',
        height: {
          xs: dimensions.navHeightMobile,
          md: dimensions.navHeightDesktop,
        },
        boxSizing: 'border-box',
      }}
    >
      <Container
        maxWidth={'lg'}
        sx={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          pt: { xs: 1, md: 2 },
        }}
      >
        <Box
          onClick={() => {
            scrollToSection(0);
            setIsMenuOpen(false);
          }}
          component={'img'}
          src={logo}
          alt={'Spaccanapoli Logo'}
          sx={{
            height: {
              xs: `calc(${dimensions.navHeightMobile} - ${theme.spacing(2)})`,
              md: `calc(${dimensions.navHeightDesktop} - ${theme.spacing(8)})`,
            },
            cursor: 'pointer',
          }}
        />
        <Navigation />
      </Container>
    </Box>
  );
};

export default Header;

