import { isSmallScreen } from '../utils/style-utils';
import { useScrollDirection } from '../hooks/useScrollDirection';
import { dimensions } from '../styles/dimensions';
import { useAppContext } from '../context/AppContext';

export const useNavigation = () => {
  const { threshold } = useScrollDirection();
  const { sections } = useAppContext();
  const isMobile = isSmallScreen();
  const scrollToSection = (id: number) => {
    if (id === 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      const marginOffset = isMobile ? 8 : 16;
      const scrollAmount = sections.find((section) => section.id === id)?.elementRef?.getClientRects()[0].y || 0;

      window.scrollBy({
        top:
          scrollAmount < 0 || scrollAmount < threshold
            ? scrollAmount - (isMobile ? dimensions.navHeightMobileRaw : dimensions.navHeightDesktopRaw) + marginOffset
            : scrollAmount + marginOffset,
        behavior: 'smooth',
      });
    }
  };
  return { scrollToSection };
};

